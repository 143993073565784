import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./pages/auth/Home";
import Login from "./pages/auth/Login"; // Giả sử bạn có một component Login
import Register from "./pages/auth/Register"; // Giả sử bạn có một component Register
import Header from "./pages/header/Header";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ForgotPassword2 from "./pages/auth/ForgotPassword2";
import Charge from "./pages/payment/Charge";
import PaymentOption from "./pages/payment/PaymentOption";
import PaymentHistory from "./pages/payment/PaymentHistory";
import Cookies from "js-cookie";
import MomoPayment from "./pages/payment/MomoPayment";
import Ranking from "./pages/layout/Ranking";
import ChangePassword from "./pages/auth/ChangePassword";
import SearchTransactionHistory from "./pages/admin/SearchTransactionHistory";
import "bootstrap/dist/css/bootstrap.min.css";
import MileStone from "./pages/payment/MileStone";
import footer from "./assets/img/Tin tức.png";
import footerTablet from "./assets/img/Footer.png";
import footerPhone from "./assets/img/Footer (1).png";
import { useEffect, useState } from "react";

function App() {
  const [screenType, setScreenType] = useState(window.innerWidth >= 992);
  const [screenType2, setscreenType2] = useState("desktop");

  useEffect(() => {
    const handleResize = () => {
      setScreenType(window.innerWidth >= 992);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    function determinescreenType2() {
      if (window.innerWidth <= 432) {
        setscreenType2("mobile");
      } else if (window.innerWidth <= 992) {
        setscreenType2("tablet");
      } else {
        setscreenType2("desktop");
      }
    }

    determinescreenType2();

    window.addEventListener("resize", determinescreenType2);

    return () => {
      window.removeEventListener("resize", determinescreenType2);
    };
  }, []);

  const getImageForscreenType2 = () => {
    switch (screenType2) {
      case "mobile":
        return footerPhone;
      case "tablet":
        return footerTablet;
      default:
        return footer;
    }
  };

  let userData = null;
  try {
    const userDataString = Cookies.get("USER_DATA");
    if (userDataString) {
      userData = JSON.parse(userDataString);
    }
  } catch (error) {
    console.error("Error parsing ACCOUNT_DATA:", error);
  }

  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          {/* <Route path="/ranking" element={<Ranking />} /> */}
          <Route
            path="/payment-option/charge"
            element={userData ? <Charge /> : <Login />}
          />
          <Route
            path="/payment-option/momo-payment"
            element={userData ? <MomoPayment /> : <Login />}
          />
          <Route
            path="/payment-option"
            element={userData ? <PaymentOption /> : <Login />}
          />
          <Route
            path="/payment-history"
            element={userData ? <PaymentHistory /> : <Login />}
          />
          <Route
            path="/milestone"
            element={userData ? <MileStone /> : <Login />}
          />
          <Route
            path="/change-password/:userName"
            element={<ChangePassword />}
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/forgot-password/:userName/:server"
            element={<ForgotPassword2 />}
          />
          <Route
            path="/dauphaiadmin/thang_nao_tim_duoc_bo_cho_10k/dit-ca-lo-chung-may/Mmye114QQh"
            element={<SearchTransactionHistory />}
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <img
          style={{ width: "100%", height: "auto" }}
          src={getImageForscreenType2()}
          alt=""
        />
      </div>
    </Router>
  );
}

export default App;
